import React, { useEffect, useRef } from 'react';
import { Box, styled } from '@mui/material';

const Container = styled(Box)`
  border-radius: 16px;
  border: 8px solid #2a2e39;
  overflow: hidden;
  background: #131722;
  position: relative;
  height: 500px;
  &:before {
    content: '';
    position: absolute;
    top: 36px;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0.3;
    filter: grayscale(0.7);
    z-index: 0;
  }
  iframe {
    position: relative;
    z-index: 1;
  }
`;

const PRICE_CHART_ID = 'price-chart-widget-container';

export const TVChartContainer = ({ pair }: any) => {
    const containerRef = useRef(null);

    useEffect(() => {
        if (typeof window === 'undefined') return;
        if (!pair) return;

        const loadWidget = () => {
            if (typeof window.createMyWidget === 'function') {
                window.createMyWidget(PRICE_CHART_ID, {
                    autoSize: true,
                    chainId: '0x171',
                    pairAddress: pair,
                    defaultInterval: '60',
                    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone ?? 'Etc/UTC',
                    theme: 'dark',
                    locale: 'en',
                    hideLeftToolbar: true,
                    hideTopToolbar: true,
                    hideBottomToolbar: true
                });
            } else {
                console.error('createMyWidget function is not defined.');
            }
        };

        if (!document.getElementById('moralis-chart-widget')) {
            const script = document.createElement('script');
            script.id = 'moralis-chart-widget';
            script.src = 'https://moralis.com/static/embed/chart.js';
            script.type = 'text/javascript';
            script.async = true;
            script.onload = loadWidget;
            script.onerror = () => {
                console.error('Failed to load the chart widget script.');
            };
            document.body.appendChild(script);
        } else {
            loadWidget();
        }
    }, [pair]);

    return (
        <Container>
            <div
                id={PRICE_CHART_ID}
                ref={containerRef}
                style={{ width: "100%", height: "100%" }}
            />
        </Container>
    );
};

export default TVChartContainer;