import { useTranslation } from '@pancakeswap/localization'
import { TradeType } from '@pancakeswap/sdk'
import { ChainId } from '@pancakeswap/chains'
import { SmartRouter, SmartRouterTrade } from '@pancakeswap/smart-router/evm'
import { formatAmount } from '@pancakeswap/utils/formatFractions'
import truncateHash from '@pancakeswap/utils/truncateHash'
import { useUserSlippage } from '@pancakeswap/utils/user'
import { useToast } from '@pancakeswap/uikit'
import { SendTransactionResult } from 'wagmi/actions'
import { INITIAL_ALLOWED_SLIPPAGE } from 'config/constants'
import { useMemo, useRef } from 'react'
import { useSwapState } from 'state/swap/hooks'
import { useTransactionAdder } from 'state/transactions/hooks'
import { calculateGasMargin, safeGetAddress } from 'utils'
import { basisPointsToPercent } from 'utils/exchange'
import { logSwap, logTx } from 'utils/log'
import { isUserRejected } from 'utils/sentry'
import { transactionErrorToUserReadableMessage } from 'utils/transactionErrorToUserReadableMessage'
import { viemClients } from 'utils/viem'
import { Address, Hex, hexToBigInt } from 'viem'
import { useSendTransaction } from 'wagmi'

import { isZero } from '../utils/isZero'

interface SwapCall {
  address: Address
  calldata: Hex
  value: Hex
}

interface WallchainSwapCall {
  getCall: () => Promise<SwapCall & { gas: string }>
}

interface SwapCallEstimate {
  call: SwapCall | WallchainSwapCall
}

interface SuccessfulCall extends SwapCallEstimate {
  call: SwapCall | WallchainSwapCall
  gasEstimate: bigint
}

interface FailedCall extends SwapCallEstimate {
  call: SwapCall | WallchainSwapCall
  error: Error
}

export class TransactionRejectedError extends Error { }

// returns a function that will execute a swap, if the parameters are all valid
export default function useSendSwapTransaction(
  account?: Address,
  chainId?: number,
  trade?: SmartRouterTrade<TradeType>, // trade to execute, required
  swapCalls: SwapCall[] | WallchainSwapCall[] = [],
): { callback: null | (() => Promise<SendTransactionResult>) } {
  const { t } = useTranslation()
  const addTransaction = useTransactionAdder()
  const { sendTransactionAsync } = useSendTransaction()
  const publicClient = viemClients[chainId as ChainId]
  const [allowedSlippage] = useUserSlippage() || [INITIAL_ALLOWED_SLIPPAGE]
  const { recipient } = useSwapState()
  const { toastWarning } = useToast()
  const recipientAddress = recipient === null ? account : recipient
  const isEstimatingRef = useRef(false)

  return useMemo(() => {
    if (!trade || !sendTransactionAsync || !account || !chainId || !publicClient) {
      return { callback: null }
    }
    return {
      callback: async function onSwap(): Promise<SendTransactionResult> {
        if (isEstimatingRef.current) {
          throw new Error('Transaction already in progress')
        }
        isEstimatingRef.current = true

        try {
          // Only estimate gas for the first swap call
          const firstCall = swapCalls[0]
          if (!firstCall) {
            throw new Error('No swap call available')
          }

          let bestCallOption: SwapCall & { gas?: string | bigint }

          if ('getCall' in firstCall) {
            bestCallOption = await firstCall.getCall() as SwapCall & { gas?: string | bigint }
            if ('error' in bestCallOption) {
              throw new Error('Route lost. Need to restart.')
            }
          } else {
            const tx = !firstCall.value || isZero(firstCall.value)
              ? { account, to: firstCall.address, data: firstCall.calldata, value: 0n }
              : {
                account,
                to: firstCall.address,
                data: firstCall.calldata,
                value: hexToBigInt(firstCall.value),
              }

            const gasEstimate = await publicClient.estimateGas(tx)
            bestCallOption = {
              ...firstCall,
              gas: calculateGasMargin(gasEstimate),
            }
          }

          const response = await sendTransactionAsync({
            account,
            to: bestCallOption.address,
            data: bestCallOption.calldata,
            value: bestCallOption.value && !isZero(bestCallOption.value) ? hexToBigInt(bestCallOption.value) : 0n,
            gas: bestCallOption.gas,
          })

          // Handle transaction response and add to transaction history
          const inputSymbol = trade.inputAmount.currency.symbol
          const outputSymbol = trade.outputAmount.currency.symbol
          const pct = basisPointsToPercent(allowedSlippage)
          const inputAmount =
            trade.tradeType === TradeType.EXACT_INPUT
              ? formatAmount(trade.inputAmount, 3)
              : formatAmount(SmartRouter.maximumAmountIn(trade, pct), 3)
          const outputAmount =
            trade.tradeType === TradeType.EXACT_OUTPUT
              ? formatAmount(trade.outputAmount, 3)
              : formatAmount(SmartRouter.minimumAmountOut(trade, pct), 3)

          const base = `Swap ${trade.tradeType === TradeType.EXACT_OUTPUT ? 'max.' : ''
            } ${inputAmount} ${inputSymbol} for ${trade.tradeType === TradeType.EXACT_INPUT ? 'min.' : ''
            } ${outputAmount} ${outputSymbol}`

          const recipientAddressText =
            recipientAddress && safeGetAddress(recipientAddress) ? truncateHash(recipientAddress) : recipientAddress

          const withRecipient = recipient === account ? base : `${base} to ${recipientAddressText}`

          addTransaction(response, {
            summary: withRecipient,
            translatableSummary: {
              text: 'Swap %inputAmount% %inputSymbol% for %outputAmount% %outputSymbol%',
              data: {
                inputAmount,
                inputSymbol,
                outputAmount,
                outputSymbol,
                ...(recipient !== account && { recipientAddress: recipientAddressText }),
              },
            },
            type: 'swap',
          })

          return response
        } catch (error) {
          if (isUserRejected(error)) {
            toastWarning(t('Swap'), t('User rejected the request'))
            throw new TransactionRejectedError(t('Transaction rejected'))
          } else {
            console.error(`Swap failed`, error)
            throw new Error(`Swap failed: ${transactionErrorToUserReadableMessage(error, t)}`)
          }
        } finally {
          isEstimatingRef.current = false
        }
      },
    }
  }, [
    trade,
    sendTransactionAsync,
    account,
    chainId,
    publicClient,
    swapCalls,
    t,
    allowedSlippage,
    recipientAddress,
    recipient,
    addTransaction,
    toastWarning
  ])
}
