/* eslint-disable no-param-reassign */
import { ERC20Token } from '@pancakeswap/sdk'
import { ChainId } from '@pancakeswap/chains'
import { Currency, NativeCurrency } from '@pancakeswap/swap-sdk-core'

import { TokenAddressMap } from '@pancakeswap/token-lists'
import { GELATO_NATIVE } from 'config/constants'
import { useAtomValue } from 'jotai'
import { useEffect, useMemo, useState } from 'react'
import { useToken as useToken_ } from 'wagmi'
import {
  combinedCurrenciesMapFromActiveUrlsAtom,
  combinedTokenMapFromActiveUrlsAtom,
  combinedTokenMapFromOfficialsUrlsAtom,
  useUnsupportedTokenList,
  useWarningTokenList,
} from 'state/lists/hooks'
import { safeGetAddress } from 'utils'
import { getContract } from 'utils/contractHelpers'
import { ethers } from 'ethers'
import { tokenAbi } from 'config/abi/tokenAbi'
import axios from 'axios'
import useUserAddedTokens from '../state/user/hooks/useUserAddedTokens'
import { useActiveChainId } from './useActiveChainId'
import useNativeCurrency from './useNativeCurrency'

const mapWithoutUrls = (tokenMap?: TokenAddressMap<ChainId>, chainId?: number) => {
  if (!tokenMap || !chainId) return {}
  return Object.keys(tokenMap[chainId] || {}).reduce<{ [address: string]: ERC20Token }>((newMap, address) => {
    const checksummedAddress = safeGetAddress(address)

    if (checksummedAddress && !newMap[checksummedAddress]) {
      newMap[checksummedAddress] = tokenMap[chainId][address].token
    }

    return newMap
  }, {})
}

const mapWithoutUrlsBySymbol = (tokenMap?: TokenAddressMap<ChainId>, chainId?: number) => {
  if (!tokenMap || !chainId) return {}
  return Object.keys(tokenMap[chainId] || {}).reduce<{ [symbol: string]: ERC20Token }>((newMap, symbol) => {
    newMap[symbol] = tokenMap[chainId][symbol].token

    return newMap
  }, {})
}

/**
 * Returns all tokens that are from active urls and user added tokens
 */
export function useAllTokens(): { [address: string]: ERC20Token } {
  const { chainId } = useActiveChainId()
  const tokenMap = useAtomValue(combinedTokenMapFromActiveUrlsAtom)
  const userAddedTokens = useUserAddedTokens()
  return useMemo(() => {
    return (
      userAddedTokens
        // reduce into all ALL_TOKENS filtered by the current chain
        .reduce<{ [address: string]: ERC20Token }>(
          (tokenMap_, token) => {
            const checksummedAddress = safeGetAddress(token.address)

            if (checksummedAddress) {
              tokenMap_[checksummedAddress] = token
            }

            return tokenMap_
          },
          // must make a copy because reduce modifies the map, and we do not
          // want to make a copy in every iteration
          mapWithoutUrls(tokenMap, chainId),
        )
    )
  }, [userAddedTokens, tokenMap, chainId])
}

export function useAllOnRampTokens(): { [address: string]: Currency } {
  const { chainId } = useActiveChainId()
  const tokenMap = useAtomValue(combinedCurrenciesMapFromActiveUrlsAtom)
  return useMemo(() => {
    return mapWithoutUrlsBySymbol(tokenMap, chainId)
  }, [tokenMap, chainId])
}

/**
 * Returns all tokens that are from officials token list and user added tokens
 */
export function useOfficialsAndUserAddedTokens(): { [address: string]: ERC20Token } {
  const { chainId } = useActiveChainId()
  const tokenMap = useAtomValue(combinedTokenMapFromOfficialsUrlsAtom)

  const userAddedTokens = useUserAddedTokens()
  return useMemo(() => {
    return (
      userAddedTokens
        // reduce into all ALL_TOKENS filtered by the current chain
        .reduce<{ [address: string]: ERC20Token }>(
          (tokenMap_, token) => {
            const checksummedAddress = safeGetAddress(token.address)

            if (checksummedAddress) {
              tokenMap_[checksummedAddress] = token
            }

            return tokenMap_
          },
          // must make a copy because reduce modifies the map, and we do not
          // want to make a copy in every iteration
          mapWithoutUrls(tokenMap, chainId),
        )
    )
  }, [userAddedTokens, tokenMap, chainId])
}

export function useUnsupportedTokens(): { [address: string]: ERC20Token } {
  const { chainId } = useActiveChainId()
  const unsupportedTokensMap = useUnsupportedTokenList()
  return useMemo(() => mapWithoutUrls(unsupportedTokensMap, chainId), [unsupportedTokensMap, chainId])
}

export function useWarningTokens(): { [address: string]: ERC20Token } {
  const warningTokensMap = useWarningTokenList()
  const { chainId } = useActiveChainId()
  return useMemo(() => mapWithoutUrls(warningTokensMap, chainId), [warningTokensMap, chainId])
}

export function useIsTokenActive(token: ERC20Token | undefined | null): boolean {
  const activeTokens = useAllTokens()

  if (!activeTokens || !token) {
    return false
  }

  const tokenAddress = safeGetAddress(token.address)

  return tokenAddress && !!activeTokens[tokenAddress]
}

// Check if currency is included in custom list from user storage
export function useIsUserAddedToken(currency: Currency | undefined | null): boolean {
  const userAddedTokens = useUserAddedTokens()

  if (!currency?.equals) {
    return false
  }

  return !!userAddedTokens.find((token) => currency?.equals(token))
}

const useTokenDetails = (token: ERC20Token, tokenAddress: string) => {
  const { chainId } = useActiveChainId()
  const [tokenDetails, setTokenDetails] = useState<{
    decimals?: number;
    symbol?: string;
    name?: string;
  } | null>(null);

  const fetchTokenDetails = async () => {
    try {

      if (token) {
        setTokenDetails({
          decimals: token.decimals,
          symbol: token.symbol,
          name: token.name,
        });
        return;
      }

      if (!tokenAddress) {
        return;
      }

      const contract = getContract({
        address: tokenAddress as `0x${string}`,
        abi: tokenAbi,
        chainId,
      }) as unknown as ethers.Contract;

      const [decimals, symbol, name] = await Promise.all([
        contract.read.decimals(),
        contract.read.symbol(),
        contract.read.name()
      ]);

      setTokenDetails({ decimals, symbol, name });
    } catch (err) {
      console.error(`Failed to fetch token details for ${tokenAddress}`, err);
    }
  };

  useEffect(() => {
    fetchTokenDetails().then(() => {
    }).catch((e) => {
      console.error("fetchTokenDetails", e)
    });
  }, [tokenAddress, token])

  return tokenDetails
}

export const useBuyBurnPairs = () => {
  const [pairBalances, setPairBalances] = useState<any>(null);

  const fetchBuyBurnPairsBalances = async () => {
    try {
      axios.get('https://poolsinfoapi.9inch.io/fetchburnpairs')
        .then((response) => {
          console.log("response", response)
          setPairBalances(response.data)
        })
        .catch((error) => {
          console.error(error)
        })

    } catch (err) {
      console.error(`Failed to fetch tokens`, err);
    }
  }

  useEffect(() => {
    fetchBuyBurnPairsBalances().then(() => {
      console.log("fetchBuyBurnPairsBalances", pairBalances)
    }).catch((e) => {
      console.error("fetchBuyBurnPairsBalances", e)
    });
  }, [])

  return pairBalances
}

// undefined if invalid or does not exist
// null if loading
// otherwise returns the token
export function useToken(tokenAddress?: string): ERC20Token | undefined | null {
  const { chainId } = useActiveChainId()
  const tokens = useAllTokens()

  const address = safeGetAddress(tokenAddress)

  const token: ERC20Token | undefined = address ? tokens[address] : undefined

  const tokenDetails = useTokenDetails(token, address)


  const { data, isLoading } = useToken_({
    address: address || undefined,
    chainId,
    enabled: Boolean(!!address && !token),
    // consider longer stale time
  })

  return useMemo(() => {
    if (token) return token
    if (!token && address && tokenDetails) return new ERC20Token(
      chainId,
      address,
      tokenDetails.decimals ?? 18,
      tokenDetails.symbol ?? 'UNKNOWN',
      tokenDetails.name ?? 'Unknown Token',
    )
    if (!chainId || !address) return undefined
    if (isLoading) return null
    if (data) {
      return new ERC20Token(
        chainId,
        data.address,
        data.decimals,
        data.symbol ?? 'UNKNOWN',
        data.name ?? 'Unknown Token',
      )
    }

    return undefined
  }, [token, address, chainId, isLoading, data])
}

export function useOnRampToken(tokenAddress?: string): Currency | undefined {
  const { chainId } = useActiveChainId()
  const tokens = useAllOnRampTokens()
  const address = safeGetAddress(tokenAddress)
  const token = tokens[tokenAddress]

  return useMemo(() => {
    if (token) return token
    if (!chainId || !address) return undefined
    return undefined
  }, [token, chainId, address])
}

export function useCurrency(currencyId?: string, noWrapped?: boolean): Currency | ERC20Token | null | undefined {
  const native = useNativeCurrency()
  const isNative =
    currencyId?.toUpperCase() === native.symbol?.toUpperCase() || (noWrapped ? false : currencyId?.toUpperCase() === native.wrapped.address?.toUpperCase()) || currencyId?.toLowerCase() === GELATO_NATIVE
  const token = useToken(isNative ? undefined : currencyId)

  return isNative ? native : token
}

export function useOnRampCurrency(currencyId: string | undefined): NativeCurrency | Currency | null | undefined {
  const native = useNativeCurrency()
  const isNative =
    currencyId?.toUpperCase() === native.symbol?.toUpperCase() || currencyId?.toLowerCase() === GELATO_NATIVE
  const token = useOnRampToken(currencyId)
  return isNative ? native : token
}
