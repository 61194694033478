import { SmartRouter } from '@pancakeswap/smart-router/evm'
import throttle from 'lodash/throttle'
import React, { useContext, useMemo, useState } from 'react'
import { ArrowUpDownIcon, Box, Flex, IconButton, LinkExternal, useModal, Text, FlexGap, ChartIcon, ChartDisableIcon, useMatchBreakpoints } from '@pancakeswap/uikit'

// import { shouldShowMMLiquidityError } from 'views/Swap/MMLinkPools/utils/exchange'
// import { MMLiquidityWarning } from 'views/Swap/MMLinkPools/components/MMLiquidityWarning'
import SettingsModal from 'components/EMenu/GlobalSettings/SettingsModal'
import { SettingsMode } from 'components/EMenu/GlobalSettings/types'
import styled from 'styled-components'
import useActiveWeb3React from 'hooks/useActiveWeb3React'

import { Currency } from '@pancakeswap/sdk'
import { useDerivedBestTradeWithMM } from '../MMLinkPools/hooks/useDerivedSwapInfoWithMM'
import { useCheckInsufficientError } from './hooks/useCheckSufficient'
import {
  FormHeader,
  FormMain,
  MMTradeDetail,
  PricingAndSlippage,
  SwapCommitButton,
  TradeDetails,
  BuyCryptoLink,
} from './containers'
import { MMCommitButton } from './containers/MMCommitButton'
import { useSwapBestTrade } from './hooks'
import PriceChartContainer from '../components/Chart/PriceChartContainer'
import { SwapFeaturesContext } from '../SwapFeaturesContext'

const BridgeContainer = styled(FlexGap) <{
  chainId?: number;
}>`
  margin-top: 20px;
  width: 100%;
  border-radius: 15px;

  background: background: rgba(0, 0, 0, 0.3);
  border-radius: 15px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  padding: 16px;
`;

const CardContainer = styled.div`
  background: transparent;
  border-radius: 16px;
  padding: 15px;
  margin: 10px auto 0;
  width: 100%;
`

//   background-color: ${({ chainId }) => (chainId === 1 ? "red" : "blue")}


const BridgeLogo = styled.img`
  width: 32px;
  height: 32px;
`

const SettingsIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.66675 5.83333C1.66675 5.3731 2.03984 5 2.50008 5H5.83342V6.66667H2.50008C2.03984 6.66667 1.66675 6.29357 1.66675 5.83333Z" fill="white" />
    <path d="M2.50008 13.3333C2.03984 13.3333 1.66675 13.7064 1.66675 14.1667C1.66675 14.6269 2.03984 15 2.50008 15H12.5001V13.3333H2.50008Z" fill="white" />
    <path d="M14.1667 13.3333V15H17.5001C17.9603 15 18.3334 14.6269 18.3334 14.1667C18.3334 13.7064 17.9603 13.3333 17.5001 13.3333H14.1667Z" fill="white" />
    <path d="M7.50008 5V6.66667H17.5001C17.9603 6.66667 18.3334 6.29357 18.3334 5.83333C18.3334 5.3731 17.9603 5 17.5001 5H7.50008Z" fill="white" />
    <path fillRule="evenodd" clipRule="evenodd" d="M9.16675 5.83337C9.16675 7.21409 8.04746 8.33337 6.66675 8.33337C5.28604 8.33337 4.16675 7.21409 4.16675 5.83337C4.16675 4.45266 5.28604 3.33337 6.66675 3.33337C8.04746 3.33337 9.16675 4.45266 9.16675 5.83337ZM7.50008 5.83337C7.50008 6.29361 7.12699 6.66671 6.66675 6.66671C6.20651 6.66671 5.83341 6.29361 5.83341 5.83337C5.83341 5.37314 6.20651 5.00004 6.66675 5.00004C7.12699 5.00004 7.50008 5.37314 7.50008 5.83337Z" fill="white" />
    <path fillRule="evenodd" clipRule="evenodd" d="M15.8334 14.1667C15.8334 15.5474 14.7141 16.6667 13.3334 16.6667C11.9527 16.6667 10.8334 15.5474 10.8334 14.1667C10.8334 12.786 11.9527 11.6667 13.3334 11.6667C14.7141 11.6667 15.8334 12.786 15.8334 14.1667ZM14.1667 14.1667C14.1667 14.6269 13.7937 15 13.3334 15C12.8732 15 12.5001 14.6269 12.5001 14.1667C12.5001 13.7065 12.8732 13.3334 13.3334 13.3334C13.7937 13.3334 14.1667 13.7065 14.1667 14.1667Z" fill="white" />
  </svg>
)

const StyledIconButton = styled(IconButton)`
  width: 44px;
  height: 32px;
  padding: 8px 12px;
  border-radius: 16px;
  background: rgba(183, 173, 209, 0.1);
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.1),
              0px 1px 1px 0px rgba(255, 255, 255, 0.1) inset;

  margin-left: 8px;

  &:hover {
    opacity: 0.8;
  }

  svg {
    width: 20px;
    height: 20px;
  }
`

type V3SwapFormProps = {
  inputCurrencyId?: string
  inputCurrency?: Currency
  outputCurrencyId?: string
  outputCurrency?: Currency
  isChartExpanded?: boolean
  setIsChartExpanded?: React.Dispatch<React.SetStateAction<boolean>>
  isChartDisplayed?: boolean
  currentSwapPrice?: {
    [key: string]: number
  }
  isMobile?: boolean
  isFullWidthContainer?: boolean
  setIsChartDisplayed?: React.Dispatch<React.SetStateAction<boolean>>
}

export const V3SwapForm: React.FC<React.PropsWithChildren<V3SwapFormProps>> = ({
  inputCurrency,
  outputCurrency,
  isChartExpanded,
  // setIsChartExpanded,
  isChartDisplayed,
  isFullWidthContainer = false,
  currentSwapPrice,
  setIsChartDisplayed,
}) => {

  const { chainId } = useActiveWeb3React();
  const { isLoading, trade, refresh, syncing, isStale, error } = useSwapBestTrade()
  const mm = useDerivedBestTradeWithMM(trade)
  const throttledHandleRefresh = useMemo(
    () =>
      throttle(() => {
        refresh()
      }, 3000),
    [refresh],
  )

  // console.log("isChartDisplayed", isChartDisplayed)

  const finalTrade = mm.isMMBetter ? mm?.mmTradeInfo?.trade : trade

  const tradeLoaded = !isLoading

  // const insufficientFundCurrency = useCheckInsufficientError(trade)
  const [onPresentSettingsModal] = useModal(<SettingsModal mode={SettingsMode.SWAP_LIQUIDITY} />)
  const { isMobile, isTablet, isDesktop } = useMatchBreakpoints()
  return (
    <>
      <CardContainer>
        <Flex width="100%" justifyContent="space-between" mb="16px" alignItems="center">
          <FormHeader onRefresh={throttledHandleRefresh} refreshDisabled={!tradeLoaded || syncing || !isStale} />
          {isDesktop && (
            <StyledIconButton onClick={() => {
              setIsChartDisplayed(!isChartDisplayed)
            }} variant="text" scale="sm">
              {
                !isChartDisplayed
                  ? <ChartIcon />
                  : <ChartDisableIcon />
              }
            </StyledIconButton>
          )}
          <StyledIconButton onClick={onPresentSettingsModal} variant="text" scale="sm">
            <SettingsIcon />
          </StyledIconButton>
        </Flex>

        <FormMain
          tradeLoading={mm.isMMBetter ? false : !tradeLoaded}
          pricingAndSlippage={
            mm.isMMBetter ? (
              <MMTradeDetail loaded={!mm.mmOrderBookTrade.isLoading} mmTrade={mm.mmTradeInfo} />
            ) : (
              <TradeDetails loaded={tradeLoaded} trade={trade} />
            )
          }
          inputAmount={finalTrade?.inputAmount}
          outputAmount={finalTrade?.outputAmount}
          swapCommitButton={
            mm?.isMMBetter ? (
              <MMCommitButton {...mm} />
            ) : (
              <SwapCommitButton trade={trade} tradeError={error} tradeLoading={!tradeLoaded} />
            )
          }
        />
        <a href='https://bridge.9inch.io/#/bridge' target='_blank' rel='noreferrer'>
          <BridgeContainer style={{ cursor: "pointer" }} flexDirection={["column", "row"]} alignItems="center" justifyContent="center" gap="8px">
            <BridgeLogo src={chainId === 1 ? "/assets/symbols/wpls.png" : "/assets/symbols/eweth.png"} />
            <FlexGap gap="8px">
              <Text color='#958BB0' fontSize="18px">{chainId === 1 ? "Pulsechain token bridge" : "Ethereum token bridge"}</Text>
              <LinkExternal color='white' href='https://bridge.9inch.io/#/bridge' bold={false} small />
            </FlexGap>
          </BridgeContainer>
        </a>

      </CardContainer>
    </>
  )
}
