import React, { useMemo, memo, useCallback, useState, useRef } from 'react'
import { Currency, Pair, Token, Percent, CurrencyAmount } from '@pancakeswap/sdk'
import { Button, Text, useModal, Flex, Skeleton, AtomBox, CopyButton, ChevronDownIcon, FlexGap, Box, useMatchBreakpoints, ChevronLeftIcon, ChevronRightIcon } from '@pancakeswap/uikit'
import { ENumericalInput, Swap as SwapUI } from '@pancakeswap/widgets-internal'
import { styled } from 'styled-components'
import { safeGetAddress } from 'utils'
import { useTranslation } from '@pancakeswap/localization'
import { useBUSDCurrencyAmount, useStablecoinPriceAmount } from 'hooks/useBUSDPrice'
import { formatDecimals } from '@pancakeswap/utils/formatBalance'
import { StablePair } from 'views/AddLiquidity/AddStableLiquidity/hooks/useStableLPDerivedMintInfo'

import { useAccount } from 'wagmi'
import { useCurrencyBalance } from 'state/wallet/hooks'
import AddToWalletButton from 'components/AddToWallet/AddToWalletButton'
import CurrencySearchModal from '../SearchModal/CurrencySearchModal'
import { CurrencyLogo, DoubleCurrencyLogo } from '../Logo'

const InputWrapper = styled.div`
  background: rgba(0, 0, 0, 0.3);
  border-radius: 16px;
  overflow: hidden;
  border: 1px solid rgba(241, 209, 227, 0.16);
  
  @media (max-width: 480px) {
    border-radius: 12px;
    margin-bottom: 4px;
  }
`

const TopSection = styled.div`
  padding: 12px 16px;
  background: rgba(183, 173, 209, 0.1);
  border: none;
  
  @media (max-width: 480px) {
    padding: 8px 12px;
  }
`

const BottomSection = styled.div`
  padding: 12px 16px;
  background: rgba(0, 0, 0, 0.31);
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  
  @media (max-width: 480px) {
    padding: 6px 12px;
  }
`

const CurrencySelectButton = styled(Button).attrs({ variant: 'text', scale: 'sm' })`
  width: auto;
  height: 42px;
  padding: 6px 12px;
  gap: 4px;
  border-radius: 24px;
  opacity: 1;
  background: rgba(183, 173, 209, 0.1);
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.1),
              0px 1px 1px 0px rgba(255, 255, 255, 0.1) inset;
  border: none;
  outline: none;

  &:hover,
  &:focus {
    background: rgba(183, 173, 209, 0.2);
  }

  &:disabled {
    box-shadow: none;
    background: transparent;
    cursor: default;
  }

  .currency-logo-name-container {
    display: flex;
    align-items: center;
    gap: 6px;
  }

  .currency-name {
    font-family: 'Riffic Free', sans-serif;
    font-size: 18px;
    font-weight: 700;
    line-height: 22px;
    text-align: left;
    color: white;
  }

  @media (max-width: 480px) {
    height: 32px;
    padding: 4px 8px;
    flex-shrink: 0;
    
    .currency-name {
      font-size: 14px;
      line-height: 18px;
    }

    img {
      width: 24px;
      height: 24px;
    }
  }
`

const InputContainer = styled(Flex)`
  flex: 1;
  flex-direction: column;
  align-items: flex-end;
  
  @media (max-width: 480px) {
    min-width: 0; // Allow container to shrink below content size
  }
`

const StyledInput = styled(ENumericalInput)`
  background: transparent;
  border: none;
  color: white;
  width: 100%;
  padding: 0;
  font-weight: bold;

  &::placeholder {
    color: #666;
  }

  @media (max-width: 480px) {
    font-size: 16px;
    height: 32px;
    text-align: right;
  }
`

const MaxButton = styled(Button)`
  font-size: 14px;
  font-weight: 400;
  background: rgba(183, 173, 209, 0.1);
  color: white;
  border-radius: 20px;
  gap: 8px;
  box-shadow: 0px 1px 1px 0px rgba(255, 255, 255, 0.1) inset;
  padding: 4px 8px;
  height: 24px;

  @media (max-width: 480px) {
    font-size: 11px;
    height: 20px;
    padding: 2px 6px;
  }
`

const BalanceIcon = () => (
  <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ marginRight: '4px' }}>
    <path d="M14.3027 5.33334H2.30273V13.3333H14.3027V5.33334Z" stroke="#958BB0" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M2.30273 5.33333C2.30273 5.06666 2.30273 3.44444 2.30273 2.66666H11.6361V5.33333" stroke="#958BB0" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M10.9697 9.33334H11.6364" stroke="#958BB0" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
)

const QuickButtons = styled(FlexGap) <{ show: boolean }>`
  // position: absolute;
  gap: 4px;
  // overflow: hidden;
  // width: ${({ show }) => show ? "auto" : "0px"};
  justify-content: flex-end;
  transition: all 0.2s; // Faster transition
  // margin: 0 2px;
  // border-radius: 4px;
  // background: #0a0108;
  // right: 100%;
  // bottom: 0;
  // ${({ theme }) => theme.mediaQueries.md} {
  //   gap: 8px;
  //   // margin: 0 8px;
  // }
`

const ArrowIndicator = styled(ChevronRightIcon) <{ show: boolean }>`
  position: absolute;
  left: -20px;
  top: 50%;
  transform: translateY(-50%);
  transition: opacity 0.2s;
  color: #B7ADD1;
  opacity: ${({ show }) => show ? '0' : '1'};
`

const StyledFlexGap = styled(FlexGap)`
  gap: 8px;
  
  @media screen and (max-width: 480px) {
    gap: 4px;
  }
`

interface CurrencyInputPanelProps {
  value: string
  onUserInput: (value: string) => void
  onInputBlur?: () => void
  onPercentInput?: (percent: number) => void
  onMax?: () => void
  showQuickInputButtons?: number[]
  showMaxButton: boolean
  maxAmount?: CurrencyAmount<Currency>
  lpPercent?: string
  label?: string
  onCurrencySelect?: (currency: Currency) => void
  currency?: Currency | null
  disableCurrencySelect?: boolean
  hideBalance?: boolean
  pair?: Pair | StablePair | null
  otherCurrency?: Currency | null
  id: string
  showCommonBases?: boolean
  commonBasesType?: string
  showSearchInput?: boolean
  beforeButton?: React.ReactNode
  disabled?: boolean
  error?: boolean | string
  showUSDPrice?: boolean
  tokensToShow?: Token[]
  currencyLoading?: boolean
  inputLoading?: boolean
  title?: React.ReactNode
  hideBalanceComp?: boolean
  showOnlyStableCoins?: boolean
}
const CurrencyInputPanel = memo(function CurrencyInputPanel({
  value,
  onUserInput,
  onInputBlur,
  onPercentInput,
  onMax,
  showQuickInputButtons = undefined,
  showMaxButton,
  maxAmount,
  lpPercent,
  label,
  onCurrencySelect,
  currency,
  disableCurrencySelect = false,
  hideBalance = false,
  beforeButton,
  pair = null, // used for double token logo
  otherCurrency,
  id,
  showCommonBases,
  commonBasesType,
  showSearchInput,
  disabled,
  error,
  showUSDPrice,
  tokensToShow,
  currencyLoading,
  inputLoading,
  title,
  hideBalanceComp,
  showOnlyStableCoins,
}: CurrencyInputPanelProps) {
  const { address: account } = useAccount()
  const [hasQuickButtons, setShowQuickButtons] = useState(true)

  const selectedCurrencyBalance = useCurrencyBalance(account ?? undefined, currency ?? undefined)
  const { t } = useTranslation()

  const timerQuickButtons = useRef<any>()

  const mode = id
  const token = pair ? pair.liquidityToken : currency?.isToken ? currency : null
  const tokenAddress = token ? safeGetAddress(token.address) : null

  // const amountInDollar = useStablecoinPriceAmount(
  //   showUSDPrice ? currency! : undefined,
  //   Number.isFinite(+value) ? +value : undefined,
  //   {
  //     hideIfPriceImpactTooHigh: false,
  //     enabled: Number.isFinite(+value),
  //   },
  // )

  const amountInDollar = useBUSDCurrencyAmount(
    showUSDPrice ? currency : undefined,
    Number.isFinite(+value) ? +value : undefined,
  )

  const [onPresentCurrencyModal] = useModal(
    <CurrencySearchModal
      onCurrencySelect={onCurrencySelect}
      selectedCurrency={currency}
      otherSelectedCurrency={otherCurrency}
      showCommonBases={showCommonBases}
      commonBasesType={commonBasesType}
      showSearchInput={showSearchInput}
      tokensToShow={tokensToShow}
      mode={mode}
      showOnlyStableCoins={showOnlyStableCoins}
    />,
  )

  // const percentAmount = useMemo(
  //   () => (
  //     showQuickInputButtons.reduce((buttons, percent) => {
  //       return {
  //         ...buttons,
  //         [percent]: maxAmount ? maxAmount.multiply(new Percent(percent, 100)).toExact() : undefined,
  //       }
  //     }, {})
  //   ),
  //   [maxAmount, showQuickInputButtons],
  // )

  const handleUserInput = useCallback(
    (val: string) => {
      onUserInput(val)
    },
    [onUserInput],
  )

  const onMaxDefault = useCallback(() => onUserInput(selectedCurrencyBalance?.toExact()), [onUserInput, selectedCurrencyBalance])
  const onPercentInputDefault = useCallback((percent: number) => onUserInput(selectedCurrencyBalance?.multiply(percent).divide(100).toExact()), [onUserInput, selectedCurrencyBalance])

  const onCurrencySelectClick = useCallback(() => {
    if (!disableCurrencySelect) {
      onPresentCurrencyModal()
    }
  }, [onPresentCurrencyModal, disableCurrencySelect])

  const decimalsLimit = useMemo(() => {
    // if (
    //   tokenAddress === '0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599' ||
    //   tokenAddress === '0xb17D901469B9208B17d916112988A3FeD19b5cA1' ||
    //   tokenAddress === '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2'
    // ) {
    //   return 4
    // }

    if (token?.decimals === 0) {
      return 0
    }

    if (token?.decimals) {
      return token.decimals
    }

    return 8
  }, [token])

  // const isAtPercentMax = (maxAmount && value === maxAmount.toExact()) || (lpPercent && lpPercent === '100')

  // const balance = !hideBalance && !!currency ? formatDecimals(String(selectedCurrencyBalance?.toExact()), 2) : undefined

  // const { isMobile } = useMatchBreakpoints()

  // const showQuickButtons = () => {
  //   setShowQuickButtons(true)
  //   if (timerQuickButtons.current)
  //     clearTimeout(timerQuickButtons.current)
  // }

  // const hideQuickButtons = () => {
  //   timerQuickButtons.current = setTimeout(() => setShowQuickButtons(false), 300) // Faster hiding
  // }

  return (
    <InputWrapper>
      <TopSection>
        <Flex justifyContent="space-between" alignItems="center" flexDirection="row">
          <Flex alignItems="center">
            {beforeButton}
            <CurrencySelectButton
              className="open-currency-select-button"
              selected={!!currency}
              disabled={disableCurrencySelect}
              onClick={onCurrencySelectClick}
            >
              <Flex alignItems="center" className="currency-logo-name-container">
                {pair ? (
                  <DoubleCurrencyLogo currency0={pair.token0} currency1={pair.token1} size={30} margin />
                ) : currency ? (
                  <CurrencyLogo currency={currency} size="30px" />
                ) : null}
                {pair ? (
                  <Text className="currency-name" id="pair" bold>
                    {pair?.token0.symbol}:{pair?.token1.symbol}
                  </Text>
                ) : (
                  <Text className="currency-name" id="pair" style={{ whiteSpace: 'nowrap' }}>
                    {(currency && currency.symbol && currency.symbol.length > 10
                      ? `${currency.symbol.slice(0, 4)}...${currency.symbol.slice(-5)}`
                      : currency?.symbol) || t('Select a currency')}
                  </Text>
                )}
              </Flex>
              {!disableCurrencySelect && <ChevronDownIcon />}
            </CurrencySelectButton>
            {token && token.address ? (
              <Flex style={{ gap: '4px' }} ml="4px" alignItems="center" onClick={(e: any) => e.stopPropagation()}>
                <CopyButton
                  width="16px"
                  buttonColor="textSubtle"
                  text={token.address}
                  tooltipMessage={t('Token address copied')}
                />
                <AddToWalletButton
                  variant="text"
                  p="0"
                  height="auto"
                  width="fit-content"
                  tokenAddress={token.address}
                  tokenSymbol={token.symbol.replace('eHEX', 'HEX').replace('eWETH', 'WETH').replace('pDAI', 'DAI').replace('pWBTC', 'WBTC').replace('WRECKED', 'WRECKED')}
                  tokenDecimals={token.decimals}
                  tokenLogo={`https://v2.9inch.io/images/symbols/${token.symbol.toLowerCase()}.png`}
                />
              </Flex>
            ) : null}
          </Flex>
          <InputContainer ml={["8px", "16px"]}>
            <StyledInput
              id={id}
              value={value}
              onUserInput={handleUserInput}
              placeholder="0.0"
              decimals={token?.decimals ?? 18}
              disabled={disabled}
            />
            {value && (
              <Text fontSize={["10px", "12px"]} color="textSubtle" mt={["2px", "4px"]}>
                {amountInDollar !== undefined ? `~$${formatDecimals(amountInDollar.toFixed(2), 2)}` : '$0.0'}
              </Text>
            )}
          </InputContainer>
        </Flex>
      </TopSection>
      {!!account && (!hideBalance) && (
        <BottomSection>
          <StyledFlexGap
            flexDirection={["column-reverse", "column-reverse", "row"]}
            justifyContent="space-between"
            alignItems={["flex-start", "flex-start", "center"]}
          >
            {!hideBalance && (
              <Flex alignItems="center" onClick={!disabled ? onMax : undefined} style={{ cursor: 'pointer' }}>
                <BalanceIcon />
                <Text color="textSubtle" fontSize={["12px", "14px"]}>
                  {!hideBalance && !!currency
                    ? t('%balance%', { balance: formatDecimals(selectedCurrencyBalance?.toExact(), Math.min(currency?.decimals, decimalsLimit, 8)) ?? t('Loading') })
                    : ' -'
                  }
                </Text>
              </Flex>
            )}
            {showMaxButton && (
              <QuickButtons show={hasQuickButtons} alignSelf={["flex-end", "flex-end"]}>
                {!!showQuickInputButtons &&
                  showQuickInputButtons.map(percent => (
                    <MaxButton key={percent} onClick={() => (onPercentInput ?? onPercentInputDefault)(percent)}>
                      <Text fontSize={["11px", "14px"]} color='#B7ADD1'>{percent}%</Text>
                    </MaxButton>
                  ))
                }
                <MaxButton onClick={onMax ?? onMaxDefault}>
                  <Text fontSize={["11px", "14px"]} color='#B7ADD1'>Max</Text>
                </MaxButton>
              </QuickButtons>
            )}
          </StyledFlexGap>
        </BottomSection>
      )}
    </InputWrapper>
  )
})

export default CurrencyInputPanel
