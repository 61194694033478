// import { useTranslation } from '@pancakeswap/localization'
// import { Currency } from '@pancakeswap/sdk'
import { Flex, FlexGap, Box, useMatchBreakpoints } from '@pancakeswap/uikit'
// import replaceBrowserHistory from '@pancakeswap/utils/replaceBrowserHistory'
// import { AppBody } from 'components/App'
import { useRouter } from 'next/router'
import { useCallback, useContext, useEffect, useState } from 'react'
// import { useSwapActionHandlers } from 'state/swap/useSwapActionHandlers'
// import { currencyId } from 'utils/currencyId'

import { useSwapHotTokenDisplay } from 'hooks/useSwapHotTokenDisplay'
// import { useCurrency } from 'hooks/Tokens'
// import { Field } from 'state/swap/actions'
import { useDefaultsFromURLSearch, useSwapState } from 'state/swap/hooks'
import { styled } from 'styled-components'
import { useSwapActionHandlers } from 'state/swap/useSwapActionHandlers'
import { Currency } from '@pancakeswap/swap-sdk-core'
import replaceBrowserHistory from '@pancakeswap/utils/replaceBrowserHistory'
import { Field } from 'state/swap/actions'
import currencyId from 'utils/currencyId'
import { useCurrency } from 'hooks/Tokens'
import { useExchangeChartManager } from 'state/user/hooks'
import Page from '../Page'
// import PriceChartContainer from './components/Chart/PriceChartContainer'
// import HotTokenList from './components/HotTokenList'
// import useWarningImport from './hooks/useWarningImport'
import { V3SwapForm } from './V3Swap'
import { StyledInputCurrencyWrapper, StyledSwapContainer } from './styles'
import { SwapFeaturesContext } from './SwapFeaturesContext'
import PriceChartContainer from './components/Chart/PriceChartContainer'
import useWarningImport from './hooks/useWarningImport'

export default function Swap() {
  const { isMobile, isTablet, isDesktop } = useMatchBreakpoints()
  const [userChartPreference, setUserChartPreference] = useExchangeChartManager(isMobile)
  const [isChartDisplayed, setIsChartDisplayed] = useState(userChartPreference)


  const { query } = useRouter()
  // const { isDesktop } = useMatchBreakpoints()
  const {
    isChartExpanded,
    // setIsChartExpanded,
    // isChartSupported,
    // isHotTokenSupported,
  } = useContext(SwapFeaturesContext)
  const [isSwapHotTokenDisplay, setIsSwapHotTokenDisplay] = useSwapHotTokenDisplay()
  // const { t } = useTranslation()
  const [firstTime, setFirstTime] = useState(true)

  useEffect(() => {
    if (firstTime && query.showTradingReward) {
      setFirstTime(false)
      setIsSwapHotTokenDisplay(true)

      if (!isSwapHotTokenDisplay && isChartDisplayed) {
        setIsChartDisplayed((currentIsChartDisplayed) => !currentIsChartDisplayed)
      }
    }
  }, [firstTime, isChartDisplayed, isSwapHotTokenDisplay, query, setIsSwapHotTokenDisplay, setIsChartDisplayed])

  // swap state & price data
  const {
    [Field.INPUT]: { currencyId: inputCurrencyId },
    [Field.OUTPUT]: { currencyId: outputCurrencyId },
  } = useSwapState()
  const inputCurrency = useCurrency(inputCurrencyId)
  const outputCurrency = useCurrency(outputCurrencyId)

  const currencies: { [field in Field]?: Currency } = {
    [Field.INPUT]: inputCurrency ?? undefined,
    [Field.OUTPUT]: outputCurrency ?? undefined,
  }

  // const singleTokenPrice = useSingleTokenSwapInfo(inputCurrencyId, inputCurrency, outputCurrencyId, outputCurrency)
  const warningSwapHandler = useWarningImport()
  useDefaultsFromURLSearch()
  const { onCurrencySelection } = useSwapActionHandlers()

  const handleOutputSelect = useCallback(
    (newCurrencyOutput: Currency) => {
      onCurrencySelection(Field.OUTPUT, newCurrencyOutput)
      warningSwapHandler(newCurrencyOutput)

      const newCurrencyOutputId = currencyId(newCurrencyOutput)
      if (newCurrencyOutputId === inputCurrencyId) {
        replaceBrowserHistory('inputCurrency', outputCurrencyId)
      }
      replaceBrowserHistory('outputCurrency', newCurrencyOutputId)
    },

    [inputCurrencyId, outputCurrencyId, onCurrencySelection, warningSwapHandler],
  )

  const PriceChartContainerWrapper = styled(Flex) <{ $isChartExpanded: boolean }>`
  flex-shrink: 0;
  height: fit-content;
  // padding: 0 16px;
  width: calc(100vw - 2em);

  border-radius: 25px;
  max-width: 436px;
  padding: 1em;
  // @media (max-width: 767px) {
  //   & {
  //   }
  // }
  
  z-index: 1;

  // ${({ theme }) => theme.mediaQueries.lg} {
  //   padding: 1em 16px;
  // }

  // ${({ theme }) => theme.mediaQueries.xxl} {
  //   ${({ $isChartExpanded }) => ($isChartExpanded ? 'padding: 1em 120px' : 'padding: 1em 16px')};
  // }
`

  const StyledPriceChartContainerWrapper = styled(Box)`
  min-width: 100%;
`
  return (
    <Page removePadding={isChartExpanded} hideFooterOnDesktop={isChartExpanded}>
      <Flex
        width="100%"
        height="100%"
        justifyContent="center"
        position="relative"
        flexDirection={isDesktop ? 'row' : 'column'}
        mt={isChartExpanded ? '24px' : null}
      >
        {(isChartDisplayed && isDesktop) && (
          <Flex mr="3rem" width="100%" maxWidth="928px" flexDirection="column">
            <PriceChartContainer
              inputCurrency={inputCurrency}
              outputCurrency={outputCurrency}
              isChartDisplayed={true}
              isFullWidthContainer
              isMobile
            />
          </Flex>
        )}
        <Flex flexDirection="column" alignItems="center">
          <StyledSwapContainer $isChartExpanded={isChartExpanded}>
            <StyledInputCurrencyWrapper mt={isChartExpanded ? '24px' : '0'}>
              <V3SwapForm isChartDisplayed={isChartDisplayed} setIsChartDisplayed={setIsChartDisplayed} />
            </StyledInputCurrencyWrapper>
          </StyledSwapContainer>
        </Flex>
      </Flex>
    </Page>
  )
}
